<script type="text/javascript">
// 定义公共的接口方法
// import axios from "axios";

// // 14服务器
// const imgUrl = "http://61.54.187.130:6504/api/picStatic/"; //获取图片
// const url2 = "http://61.54.187.130:6504/api/ipis-upms"; //系统用户信息接口
// const url = "http://61.54.187.130:6504/api/ipis-revenueserver"; //普通数据接口
// const queryUrl = "http://61.54.187.130:6504/api/ipis-queryrevenue"; //查询数据接口
// const loginUrl = "http://61.54.187.130:6504/api/login/doLogin"; //登录接口
// const pageUrl = "http://61.54.187.130:6504";

// const imgUrl = "http://localhost:9001/picStatic/"; //获取图片
// const url2 = "http://localhost:9001/ipis-upms"; //系统用户信息接口
// const url = "http://localhost:9001/ipis-revenueserver"; //普通数据接口
// const queryUrl = "http://localhost:9001/ipis-queryrevenue"; //查询数据接口
// const loginUrl = "http://localhost:9001/login/doLogin"; //登录接口
// const pageUrl = "http://localhost:5001/";
const url = "http://localhost:8088/";


export default {
  url,
  // imgUrl,
  // url2,
  // loginUrl,
  // queryUrl,
  // pageUrl,
  // 加密token
  // encrypt(token) {
  //   let letters = ["p", "q", "w", "e", "r", "t", "y", "u", "i", "O"];
  //   let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  //   let date_ = new Date();
  //   let year = date_.getFullYear();
  //   let month = date_.getMonth() + 1;
  //   let day = date_.getDate();
  //   if (month < 10) month = "0" + month;
  //   if (day < 10) day = "0" + day;
  //   let hours = date_.getHours();
  //   let mins = date_.getMinutes();
  //   let secs = date_.getSeconds();
  //   if (hours < 10) hours = "0" + hours;
  //   if (mins < 10) mins = "0" + mins;
  //   if (secs < 10) secs = "0" + secs;
  //   let timestamp =
  //     year + "" + month + "" + day + "" + hours + "" + mins + "" + secs;
  //   for (let i = 0; i < letters.length; i++) {
  //     timestamp = timestamp.replace(new RegExp(numbers[i], "g"), letters[i]);
  //   }
  //   let random = [
  //     "a",
  //     "s",
  //     "d",
  //     "f",
  //     "g",
  //     "h",
  //     "j",
  //     "k",
  //     "l",
  //     "z",
  //     "x",
  //     "c",
  //     "v",
  //     "b",
  //     "n",
  //     "m",
  //     "1",
  //     "2",
  //     "3",
  //     "4",
  //     "5",
  //     "6",
  //     "7",
  //     "8",
  //     "9",
  //     "0"
  //   ];
  //   while (timestamp.length < token.length) {
  //     let num = random[Math.floor(Math.random() * random.length)];
  //     timestamp += num;
  //   }
  //   let i1 = Math.min(timestamp.length, token.length);
  //   let tokenPro = "";
  //   for (let i = 0; i < i1; i++) {
  //     tokenPro += token.charAt(i);
  //     tokenPro += timestamp.charAt(i);
  //   }
  //   return tokenPro;
  // },
  // 获取 区站信息==============
  // getinitTreeData() {
  //   return axios({
  //     method: "GET",
  //     url: url + "/revenue/getDistrictsDistrictsStation",
  //     withCredentials: true,
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       access_token: this.encrypt(sessionStorage.getItem("access_token"))
  //     }
  //   })
  //     .then(res => {
  //       return res.data.data;
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // },
  // // ======.catch error ================/
  // backError(error) {
  //   let status = null;
  //   status = error.request.status;
  //   if (status == 401) {
  //     // alert("暂无权限");
  //     swal({
  //       title: "暂无权限!",
  //       icon: "warning",
  //       button: "确 认！"
  //     });
  //   } else if (status == 404) {
  //     swal({
  //       title: "服务器暂未启动，请稍后重试!",
  //       icon: "warning",
  //       button: "确 认！"
  //     });
  //   } else if (status == 403) {
  //     swal({
  //       title: "登录过期！请重新登录!",
  //       icon: "warning",
  //       button: "确 认！"
  //     });
  //     window.location.href = pageUrl;
  //   }
  // }
  // // =======.catch error END=============
};
</script>
