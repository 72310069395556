<template>
  <div>
    <div class="home">
    </div>
    <div class="login_container">
      <div class="login_box">
        <!-- 登录表单区域 -->
        <el-form @submit.native.prevent
                 :rules="rules"
                 :model="loginForm">
          <!-- 用户名 -->
          <el-form-item>
            <el-input placeholder="用户名" prop="username"
                      @keydown.enter.native="submitForm"
                      v-model="loginForm.username"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item>
            <el-input placeholder="密码" show-password
                      @keydown.enter.native="submitForm"
                      v-model="loginForm.password"
            ></el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item>
            <el-input class="code" placeholder="验证码"
                      @keydown.enter.native="submitForm"
                      v-model="loginForm.verifiable"
            ></el-input>
            <a>
              <img/>
            </a>
          </el-form-item>
          <!-- 记住我 -->
          <el-form-item>
            <el-checkbox label="记住我" class="rememberMe"></el-checkbox>
          </el-form-item>
          <!-- 登录按钮 -->
          <el-form-item>
            <el-button type="primary" @click="submitForm">登录</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">备案号:</a>
      <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022003225号-1</a></div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      loginForm: {
        username: "", //用户名
        password: "", //密码
        verifiable: ""//验证码
      },
      rules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        password: [
          {required: true, message: "请输入用户密码", trigger: "blur"}
        ]
      }
    }
  },
  components: {},
  methods: {//点击登录调用方法
    submitForm() {
      let _this = this;
      //保存的账号
      // let name = this.loginForm.username;
      let name = 'yaohw';
      //保存的密码
      // let pass = this.loginForm.password;
      let pass = 'yaohw';
      //验证码
      // let verifiable = this.loginForm.verifiable;
      let verifiable = '1';

      //对密码做处理
      // let myPassword = this.loginForm.password + "|" + new Date().getTime(); //当前时间 时间搓
      // let encryPass = this.Myencrypt(myPassword); //加密密码
      let loginData = {
        username: name, //用户名
        password: pass, //密码
        verifiable: verifiable //验证码
      };

      if (name === "" || name == null) {
        this.$message({
          message: "请输入账号",
          type: "warning",
          duration: 3000
        });
      } else if (pass === "" || pass == null) {
        this.$message({
          message: "请输入密码",
          type: "warning",
          duration: 3000
        });
      } else if (verifiable === "" || verifiable == null) {
        this.$message({
          message: "请输入验证码",
          type: "warning",
          duration: 3000
        });
      } else {
        // 已登录标识
        //登录接口
        _this.axios({
          method: "GET",
          url: this.$global.url + "api/oauth/token",
          withCredentials: true, // 允许携带cookie
          data: this.$qs.stringify(loginData)
        })
            .then(res => {
              if (_this.checked === true) {
                let userinfo = {
                  username: _this.loginForm.username,
                  password: _this.loginForm.password,
                  checked: true
                };
                localStorage.setItem("userinfo", JSON.stringify(userinfo));
              } else {
                localStorage.removeItem("userinfo");
              }
              if (res.data.success) {
                console.log(res.data);
                let token = res.data.data.token;
                // let tokenPro = _this.$global.encrypt(token);
                sessionStorage.setItem("token", "1");
                sessionStorage.setItem("loginFlag", "1");
                sessionStorage.setItem("access_token", token); //获取登录token
              } else {
                console.log(res.data);
                _this.$message({
                  message: `${res.data.message}`,
                  type: "warning",
                  duration: 3000
                });
              }
            })
            .catch(res => {
              console.log(res);
            });
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

.home {
  background: url("../../static/imgs/bk.png");
  background-size: 100% auto;
  height: 100%;
  width: 100%;
  position: fixed;
}

.login_container {
  background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
  /*background-image: url("../images/bg_login.png");*/
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.login_box {
  width: 290px;
  height: 350px;
  /* background-color: #fff; */
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.el-form {
  padding: 32px;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.el-button {
  width: 100%;
}

.code {
  width: 45%;
}
.beian{
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

</style>
