import {createApp} from 'vue'

import ElementUI from 'element-plus'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'element-plus/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './components/common'
import qs from "qs"

const app = createApp(App)
app.config.globalProperties.$global = global
app.config.globalProperties.$axios = axios
app.config.globalProperties.$qs = qs

app.use(store).use(router)
    .use(ElementUI).use(VueAxios, axios)
    .mount('#app')
